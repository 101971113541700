import {
  create,
  customer
} from './create.js'

import {
  billingAddress,
  shippingAddress,
  payment
} from './update.js'
/**
 * @namespace session
 */
export default function session () {
  return {
    create: create.bind(this),
    customer: customer.bind(this),
    billingAddress: billingAddress.bind(this),
    shippingAddress: shippingAddress.bind(this),
    payment: payment.bind(this)
  }
}
