'use strict'

/**
 * @memberof payment
 * @module setBrands
 * @function
 * @param {Array} acceptedBrands // [visa, mastercard, ...]
 * @returns {Array} brands formmated [{key: 'visa', value: 'Visa'}, ...]
 */

export function setBrands (acceptedBrands = []) {
  const brands = {
    visa: 'Visa',
    mastercard: 'Mastercard',
    hipercard: 'Hipercard',
    discover: 'Discover',
    diners: 'Diners',
    amex: 'Amex',
    elo: 'Elo',
    jcb: 'JCB',
    credz: 'Credz',
    senff: 'Senff',
    cabal: 'Cabal',
    sorocred: 'Sorocred',
    banescard: 'Banescard',
    cassol: 'Cassol',
    unionpay: 'UnionPay'
  }

  const filteredBrands = acceptedBrands.filter(brand => Object.keys(brands).includes(brand))
  if (filteredBrands.length) {
    return filteredBrands.map(key => ({ key, value: brands[key] }))
  }
  return Object.keys(brands).map(key => ({ key: key, value: brands[key] }))
}
