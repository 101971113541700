'use strict'

/**
 * @memberof google
 * @module get
 * @function
 * @param {string} paymentLinkId
 * @param {string} walletType
 * @returns {Promise} Google pay data
 */
export function get (paymentLinkId, walletType) {
  return this.request.get(`${this.checkout_bff}/paymentlinks/${paymentLinkId}/digital-wallets/${walletType}`)
}
