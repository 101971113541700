'use strict'

import {
  get
} from './read.js'
/**
 * @namespace google
 */
export default function googlePay () {
  return {
    read: get.bind(this)
  }
}
