'use strict'
import http from './http'
import { requestInterceptor, errorRequestInterceptor, responseInterceptor, errorResponseInterceptor } from './interceptors.js'

http.interceptors.request.use(requestInterceptor, errorRequestInterceptor)
http.interceptors.response.use(responseInterceptor, errorResponseInterceptor)

export const request = {
  get: (endpoint) => http.get(endpoint).catch(e => Promise.reject(e)),
  post: (endpoint, body) => http.post(endpoint, body).catch(e => Promise.reject(e)),
  delete: (endpoint, body) => http.delete(endpoint, { data: body }).catch(e => Promise.reject(e)),
  put: (endpoint, body) => http.put(endpoint, body).catch(e => Promise.reject(e)),
  patch: (endpoint, body) => http.patch(endpoint, body).catch(e => Promise.reject(e))
}
