'use strict'

/**
 * @memberof session
 * @module update
 * @function
 * @param {string} tokenId
 * @param {string} sessionId
 * @param {Object} body
 * @returns {Promise}
 */
export function billingAddress (tokenId, sessionId, body) {
  return this.request.patch(`${this.checkout_bff}/paymentlinks/${tokenId}/sessions/${sessionId}/billing-address`, body)
}

export function shippingAddress (tokenId, sessionId, body) {
  return this.request.patch(`${this.checkout_bff}/paymentlinks/${tokenId}/sessions/${sessionId}/shipping`, body)
}

export function payment (tokenId, sessionId, body) {
  return this.request.patch(`${this.checkout_bff}/paymentlinks/${tokenId}/sessions/${sessionId}/payment`, body)
}
