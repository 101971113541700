'use strict'

/**
 * @memberof tds
 * @module get
 * @function
 * @param {string} token - Checkout token
 */
export function get (token) {
  return this.request.get(`${this.checkout_bff}/paymentlinks/${token}/threed-secure/token`)
}
