'use strict'

import {
  create
} from './create.js'

/**
 * @namespace tokenizer
 */
export default function tokenizer () {
  return {
    tokenizeCard: create.bind(this)
  }
}
