'use strict'

export function setSimpleQuery (queryName, field, nestedFields) {
  return { query: `query ${queryName} ` + `{ ${field} ${nestedFields ? '{ ' + nestedFields + ' }' : ''} }` }
}

/**
 * @memberof utils
 * @module getBrandByBin
 * @function
 * @param {string} bin - Bin number
 * @returns {Promise} Brand info
 */
export function getBrandByBin (bin) {
  const brandFields = ['key', 'name', 'gaps', 'lenghts', 'mask', 'cvv', 'image', 'bins']
  // eslint-disable-next-line
  const brandQuery = setSimpleQuery('UtilitiesQuery', `brands (bin: \"${bin}\")`, brandFields)
  return this.request.post(`${this.utilities_api}/utilities/v2/graphql`, JSON.stringify(brandQuery))
}

export function getBrand (bin) {
  return this.request.get(`${this.utilities_api}/bin/v1/${bin}`)
}
