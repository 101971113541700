'use strict'

/**
 * @memberof token
 * @module get
 * @function
 * @param {string} token - Checkout token
 * @returns {Promise} Token data
 */
export function get (token) {
  return this.request.get(`${this.checkout_api}/tokens/${token}`)
}
