'use strict'

import {
  create
} from './create.js'
/**
 * @namespace clickToPay
 */
export default function clickToPay () {
  return {
    create: create.bind(this)
  }
}
