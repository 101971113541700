'use strict'

/**
 * @memberof payment
 * @module create
 * @function
 * @param {string} publickey // Account public key
 * @param {Object} body
 * @returns {Promise} Create Merchant
 */
export function create (publickey, body) {
  return this.request.post(`${this.checkout_api}/payments?appId=${publickey}`, body)
}
