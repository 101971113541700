'use strict'

/**
 * @memberof session
 * @module create
 * @function
 * @param {string} tokenId
 * @param {string} sessionId
 * @param {Object} body
 * @returns {Promise}
 */
export function create (tokenId, body) {
  return this.request.post(`${this.checkout_bff}/paymentlinks/${tokenId}/sessions`, body)
}

export function customer (tokenId, sessionId, body) {
  return this.request.post(`${this.checkout_bff}/paymentlinks/${tokenId}/sessions/${sessionId}/customer`, body)
}
