'use strict'
import CheckoutCore from '../../index.js'

export function requestInterceptor (config) {
  if (CheckoutCore.getInstance().version === 2 &&
    config.url.indexOf('/tokens') !== -1) {
    return config
  }
  config.headers['X-Javascript-Enabled'] = true
  config.headers['X-Language'] = navigator.userLanguage || navigator.language
  config.headers['X-Color-Depth'] = window.screen.colorDepth
  config.headers['X-Screen-Width'] = window.innerWidth
  config.headers['X-Screen-Height'] = window.innerHeight
  config.headers['X-TimeZone'] = new Date().getTimezoneOffset()
  config.headers['X-Theme'] = CheckoutCore.getInstance().theme
  config.headers['Access-Control-Allow-Origin'] = '*'
  const recaptcha = CheckoutCore.getInstance().recaptcha
  if (recaptcha !== null) {
    config.headers['x-captcha-token'] = recaptcha
  }
  config.headers['Content-Type'] = 'application/json'
  return config
}

export function responseInterceptor (response) {
  if (CheckoutCore.getInstance().version === 2 && response.status === 201 &&
    response.headers && response.headers.location &&  
    response.headers.location.indexOf('/session') !== -1) {
    return response
  }
  return response.data
}

export function errorResponseInterceptor (error) {
  const baseResponse = {
    type: 'response',
    status: (error && error.response) ? error.response.status : null,
    message: error.message,
    stack: error.stack,
    body: (error && error.response) ? error.response.data : null
  }

  return Promise.reject(baseResponse)
}

export function errorRequestInterceptor (error) {
  const body = {
    type: 'request',
    status: error.request.status,
    message: error.message,
    stack: error.stack
  }
  return Promise.reject(body)
}
