'use strict'

/**
 * @memberof tokenize-card
 * @module create
 * @function
 * @param {string} publickey // Account public key
 * @param {Object} body
 * @returns {Promise} Tokenize card
 */
export function create (publickey, body) {
  return this.request.post(`${this.mark1_api}/tokens?appId=${publickey}`, body)
}
