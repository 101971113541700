'use strict'

import {
  create
} from './create.js'

import { setBrands } from './read'
/**
 * @namespace payment
 */
export default function payment () {
  return {
    create: create.bind(this),
    setBrands: setBrands.bind(this)
  }
}
